import React, { useEffect, useState } from 'react';
// import useMaterialMediaQuery from '../../packages/use-material-media-query';
import Grid from '@material-ui/core/Grid';
import HorizontalScroll from '../../packages/horizontal-scroll';
import CommonHeaderText from '../../packages/common-header-text';
import { capitalizeFirstLetter, slugifyURL } from '../../util';
// import { makeStyles } from '@material-ui/core/styles';
import HBImage from '../../packages/hbimage';
import compatible_collection from '@/data/compatibleCollection.json';
import useWindow from '@/hooks/useWindow';
import Variables from 'styles/variables.module.scss';
import classes from './compatiblecollection.module.scss';

// const useStyles = makeStyles({
//   highlightWord: {
//     color: Variables.darkorangeColor,
//     fontWeight: '700',
//     fontFamily: 'Open Sans',
//     position: 'relative !important',
//     left: '0px !important',
//     top: '0px !important',
//   },
// });

const CompatibleCollection = props => {
  const { propertyCountdata, city, module_type, isMobileView } = props;
  // const [small] = useMaterialMediaQuery();
  const [compitableData, setCompitableData] = useState(compatible_collection);

  const highlightedWord = word => {
    return <span className={classes.highlightWord}>{word}</span>;
  };

  useEffect(() => {
    try {
      const filteredData = compatible_collection.filter(
        e => propertyCountdata[e['dataKey']] != 0
      );
      setCompitableData(filteredData);
    } catch (e) {
      console.error('COMPITABLE ERROR : ', 'unable to filter.');
    }
  }, [propertyCountdata]);

  // const classes = useStyles();
  const hasWindow = useWindow();

  const redirectToSearch = rentalType => {
    let url = `/${module_type}/properties-for-${rentalType.toLowerCase()}-in-${city?.name.toLowerCase()}`;
    if (rentalType == 'all') {
      url = `/${module_type}/properties-in-${city.name.toLowerCase()}`;
    }
    if (hasWindow) {
      window.location.href = url;
    }
  };

  return (
    <section className="compatible-rental-mob">
      <div className="container">
        {isMobileView ? (
          <Grid container>
            <Grid
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="center"
              container>
              <CommonHeaderText
                text={
                  <span style={{ fontWeight: 'bold' }}>
                    {highlightedWord(capitalizeFirstLetter('Compatible '))}
                    {capitalizeFirstLetter('Rental Collection')}
                  </span>
                }
                variant="h1"
                style={{
                  fontFamily: 'Open Sans !Important',
                  fontWeight: '700',
                  lineHeight: '1',
                  fontSize: '18px',
                  color: Variables.primaryColor,
                  paddingRight: '18px',
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="center"
              container>
              <CommonHeaderText
                text={
                  <span style={{ fontFamily: 'Open Sans' }}>
                    {' '}
                    In {city.name}
                  </span>
                }
                variant="h6"
                style={{
                  fontFamily: 'Open Sans !Important',
                  fontWeight: '700',
                  lineHeight: '1.8',
                  fontSize: '13px !important',
                  color: 'rgb(96, 96, 96)',
                  textTransform: 'capitalize',
                }}
              />
            </Grid>
          </Grid>
        ) : (
          compitableData.length > 0 && (
            <Grid container>
              <Grid
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="center"
                container>
                <CommonHeaderText
                  text={
                    <span>
                      {highlightedWord('Compatible Rental Collection')} In{' '}
                      {city?.name}
                    </span>
                  }
                  variant="h1"
                  style={{
                    fontFamily: 'Open Sans !Important',
                    fontWeight: '500',
                    lineHeight: '1',
                    fontSize: '28px',
                    color: Variables.primaryColor,
                    paddingRight: '18px',
                    textTransform: 'capitalize',
                    marginBottom: '5px',
                    position: 'relative',
                    // marginBottom: '10px',
                    // marginTop: '10px',
                    bottom: '20px',
                  }}
                />
              </Grid>
            </Grid>
          )
        )}
        {compitableData.length > 0 && (
          <Grid container className="card-section">
            <Grid item xs={12} spacing={2}>
              <>
                {isMobileView ? (
                  <Grid container spacing={2}>
                    {compitableData &&
                      compitableData.map(data => {
                        const { images, heading, subheadingKey, dataKey } =
                          data;
                        let url = slugifyURL(
                          `/${module_type}/properties-for-${subheadingKey.toLowerCase()}-in-${city?.name.toLowerCase()}`
                        );
                        if (subheadingKey === 'all') {
                          url = slugifyURL(
                            `/${module_type}/properties-in-${city?.name.toLowerCase()}`
                          );
                        }

                        return (
                          <Grid
                            xs={6}
                            key={subheadingKey}
                            item
                            // onClick={() => redirectToSearch(subheadingKey)}
                          >
                            <a
                              href={url}
                              // target="_blank"
                              rel="noreferrer"
                              className="card-link"
                              // onClick={(e) => redirectToSearch(subheadingKey, e)}
                            >
                              <div className="Rentalproperty">
                                <div className="rentalImages">
                                  <HBImage
                                    width="273px"
                                    height="150px"
                                    src={images}
                                    alt="customers-img"
                                    title="customers-img"
                                  />
                                </div>
                                {propertyCountdata && (
                                  <CommonHeaderText
                                    text={
                                      <div>{`${propertyCountdata[dataKey]} properties`}</div>
                                    }
                                    variant="h4"
                                    style={{
                                      color: '#FFFFFF',
                                      fontSize: '13px',
                                      fontFamily: 'Open Sans',
                                      fontWeight: 'bold',
                                      marginBottom: '15px',
                                      marginTop: '15px',
                                      textAlign: 'Left',
                                      position: 'absolute',
                                      bottom: isMobileView ? '38px' : '42px',
                                      left: '13px',
                                      zIndex: '1',
                                      cursor: 'pointer',
                                    }}
                                  />
                                )}
                                <Grid className="rentalHeadText">
                                  <CommonHeaderText
                                    text={
                                      heading ==
                                      'For Tenants with Company Lease'
                                        ? 'Company Lease'
                                        : heading
                                    }
                                    variant="h4"
                                    style={{
                                      color: Variables.darkColor,
                                      fontSize: '13px',
                                      fontFamily: 'Open Sans',
                                      marginBottom: '5px',
                                      whiteSpace: 'break-spaces',
                                      fontWeight: 'bold',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Grid>
                              </div>
                            </a>
                          </Grid>
                        );
                      })}
                  </Grid>
                ) : (
                  <HorizontalScroll
                    itemClass=" MuiGrid-root MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-sm-6"
                    inertiaScrolling={true}
                    inertiaScrollingSlowdown={0.5}
                    // componentRef={componentRef}
                    data={
                      compitableData &&
                      compitableData.map(data => {
                        const { images, heading, subheadingKey, dataKey } =
                          data;
                        let url = slugifyURL(
                          `/${module_type}/properties-for-${subheadingKey.toLowerCase()}-in-${city?.name.toLowerCase()}`
                        );
                        if (subheadingKey === 'all') {
                          url = slugifyURL(
                            `/${module_type}/properties-in-${city.name.toLowerCase()}`
                          );
                        }
                        return (
                          <div
                            className="Rentalproperty"
                            key={subheadingKey}
                            onClick={() => redirectToSearch(subheadingKey)}>
                            <a
                              href={url}
                              // target="_blank"
                              rel="noreferrer"
                              className="card-link">
                              <div className="rentalImages">
                                <HBImage
                                  width="273px"
                                  height="150px"
                                  src={images}
                                  alt="customers-img"
                                  title="customers-img"
                                />
                              </div>
                              {propertyCountdata && (
                                <CommonHeaderText
                                  text={
                                    <span>{`${propertyCountdata[dataKey]} properties`}</span>
                                  }
                                  variant="h4"
                                  style={{
                                    color: '#FFFFFF',
                                    fontSize: '18px',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 'bold',
                                    marginBottom: '15px',
                                    marginTop: '15px',
                                    textAlign: 'Left',
                                    position: 'absolute',
                                    bottom: '42px',
                                    left: '13px',
                                    zIndex: '1',
                                    cursor: 'pointer',
                                  }}
                                />
                              )}
                              <Grid className="rentalHeadText">
                                <CommonHeaderText
                                  text={heading}
                                  variant="h4"
                                  style={{
                                    color: Variables.darkColor,
                                    fontSize: '15px',
                                    fontFamily: 'Open Sans',
                                    marginBottom: '5px',
                                    whiteSpace: 'break-spaces',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Grid>
                            </a>
                          </div>
                        );
                      })
                    }
                    innerWrapperClass="PropertyBlogItem1"
                  />
                )}
              </>
            </Grid>
          </Grid>
        )}
      </div>
    </section>
  );
};

export default CompatibleCollection;
