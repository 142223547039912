import React, { useRef, useEffect, useState } from 'react';
import handleViewport from 'react-in-viewport';
import {
  geBlogPostProjects,
  nextBlogPostProjects,
  addWishlist,
} from '../../../redux/actions/main';
import FallbackCard from '../Common/FallbackCard';
import { connect } from 'react-redux';
import ViewAll from '../Common/ViewAll';
import Grid from '@material-ui/core/Grid';
import CommonHeaderText from '../../packages/common-header-text';
import HorizontalScrollButtons from '../Common/HorizontalScrollButtons';
import HorizontalScroll from '../../packages/horizontal-scroll';
import { capitalizeFirstLetter, gotoPropertyDetailsPage } from '../../util';
import _ from 'lodash';
import useAccessToken from '../../packages/use-access-token';
import useUserInfo from '../../packages/use-user-info';
import HBImage from '../../packages/hbimage';
// import MobHorizontalScroll from '../../packages/mob-horizontal-scroll';
import MobHorizontalScrollNew from '@/components/packages/mob-horizontal-scroll-new';
import PersonIcon from '@mui/icons-material/Person';
// import useMaterialMediaQuery from '../../packages/use-material-media-query';
import ClassNames from 'classnames';
import Box from '@mui/material/Box';
import Variables from 'styles/variables.module.scss';
import classes from './blogpost.module.scss';
// import Link from 'next/link';
// All items component
// Important! add unique key
export const Menu = (
  list,
  loading,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  toggleWishlist,
  openLoginModal,
  module_type,
) =>
  list &&
  list.length > 0 &&
  list
    .filter(project => {
      if (project) {
        return true;
      }
    })
    .map((el, index) => {
      return (
        <MenuItem
          text={el}
          key={index}
          index={index}
          loading={loading}
          open_modal={open_modal}
          propertyDetailsClick={propertyDetailsClick}
          propertyDetailsGallery={propertyDetailsGallery}
          toggleWishlist={toggleWishlist}
          openLoginModal={openLoginModal}
          module_type={module_type}
        />
      );
    });

const MenuItem = ({ text, index, module_type }) => {
  const { author, category, date, image, title, view_all_link, link, isMobileView } = text;
  // const [small, medium, large] = useMaterialMediaQuery();
  const knowledgeCenterContainer = theme => ({
    padding: '15px 19px',
    [theme.breakpoints.down('sm')]: {
      padding: '13px',
    },
  });
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  //convert date into required format
  function convert(str) {
    const dateBlog = new Date(str),
      mnth = ('0' + (dateBlog.getMonth() + 1)).slice(-2);
    return [months[mnth - 1], dateBlog.getFullYear()].join(' ');
  }
  const stopEventBubbling = e => {
    e.stopPropagation();
  };
  const handleOnBlogClick = e => {
    e.preventDefault();
    window.open(link, '_blank', 'noopener,noreferrer');
  };
  return (
    <>
      <Grid
        className={ClassNames([
          'cardDetails1',
          index == 5 && (!isMobileView)
            ? module_type == 'rent'
              ? 'cardRentDetails'
              : 'cardDetailsBox'
            : 'cardDetailsBox1',
        ])}
        onClick={stopEventBubbling}>
        <Grid className="cardContinerDetails" onClick={handleOnBlogClick}>
          <Grid item className={classes.borderimage}>
            {index == 5 ? (
              <Grid
                style={{
                  width: '350px',
                  height: '204px',
                }}></Grid>
            ) : (
              <HBImage
                src={image}
                height="221px"
                width="372px"
                title={text.title.rendered}
                alt={title}
              />
            )}
          </Grid>
          {isMobileView && (
            <>
              {index == 5 ? (
                <Grid
                  container
                  style={{
                    position: 'absolute',
                    zIndex: '99',
                    top: '0',
                    padding: '27px 8px',
                  }}>
                  <Grid
                    item
                    lg={7}
                    xs={12}
                    style={{ display: 'block', justifyContent: 'center' }}>
                    <CommonHeaderText
                      text="Knowledge Center"
                      // variant="h6"
                      style={{
                        fontFamily: 'Open sans',
                        fontWeight: 'bold',
                        fontSize: '17px',
                        color: Variables.darkColor,
                        paddingBottom: '4px',
                        // paddingTop: '13px',
                        paddingLeft: '14px',
                      }}
                    />
                  </Grid>
                  <Box sx={knowledgeCenterContainer}>
                    <CommonHeaderText
                      text={
                        <>
                          Providing a unique overview of the real estate market,
                          financial advice for homebuyers and sellers, and
                          indeapth information about local markets.
                        </>
                      }
                      variant="p"
                      style={{
                        fontFamily: 'Open sans !important',
                        fontWeight: '400',
                        fontSize: '13px',
                        color: Variables.darkColor,
                        paddingBottom: '10px',
                        paddingTop: isMobileView ? '0px' : '20px',
                        whiteSpace: 'normal',
                        lineHeight: '19px',
                        height: '100%',
                        textAlign: 'justify',
                        width: '100%',
                      }}
                    />
                  </Box>
                  <Grid container>
                    <Grid item xs={12} className="mobbBlogButton">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          display: 'block',
                          justifyContent: 'center',
                          width: '100% !important',
                          borderRadius: '6px',
                        }}
                        href={'/' + view_all_link}>
                        Connect Now
                      </a>
                    </Grid>
                  </Grid>

                  <Grid container style={{ height: '47px' }}></Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  style={{
                    paddingBottom: '7px',
                    paddingTop: '5px',
                    position: 'absolute',
                    top: '0px',
                    zIndex: '99',
                  }}>
                  <Grid
                    item
                    xs={8}
                    style={{
                      textAlign: 'left',
                    }}>
                    <CommonHeaderText
                      text={
                        <>
                          {' '}
                          <PersonIcon style={{ fontSize: '14px' }} /> By{' '}
                          {author}
                        </>
                      }
                      style={{
                        fontFamily: 'Open sans',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#FFF',
                        paddingBottom: '4px',
                        paddingLeft: '10px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'right' }}>
                    <CommonHeaderText
                      text={category ?? null}
                      variant="p"
                      style={{
                        fontFamily: 'Open sans',
                        fontWeight: 'bold',
                        fontSize: '13px',
                        color: '#FFFFFF',
                        TextAline: 'right',
                        paddingBottom: '4px',
                        paddingRight: '10px',
                        width: '100%',
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              <div></div>
            </>
          )}
          {!isMobileView &&
            (index == 5 ? (
              <div className="blogLastBox">
                <Grid
                  container
                  style={{
                    position: 'absolute',
                    zIndex: '99',
                    top: '0',
                    padding: '24px 1px',
                  }}>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    style={{ display: 'block', justifyContent: 'center' }}>
                    <CommonHeaderText
                      text="Knowledge Center"
                      // variant="h6"
                      style={{
                        fontFamily: 'Open sans',
                        fontWeight: 'bold',
                        fontSize: '17px',
                        color: Variables.darkColor,
                        paddingBottom: '4px',
                        paddingTop: '13px',
                        paddingLeft: '18px',
                      }}
                    />
                  </Grid>
                  <Box sx={knowledgeCenterContainer}>
                    <CommonHeaderText
                      text={
                        <>
                          Providing a unique overview of the real estate market,
                          financial advice for homebuyers and sellers, and
                          indeapth information about local markets.
                        </>
                      }
                      variant="p"
                      style={{
                        fontWeight: '400',
                        fontSize: '14px',
                        color: Variables.darkColor,
                        whiteSpace: 'normal',
                        lineHeight: '24px',
                        fontFamily: 'Open sans !Important',
                        height: '100%',
                        width: '100%',
                      }}
                    />
                  </Box>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      className="blogButton"
                      style={{ display: 'flex', justifyContent: 'center' }}>
                      <a
                        style={{
                          height: '47px',
                          display: 'block',
                          justifyContent: 'center',
                          width: '89% !important',
                          borderRadius: '6px',
                          marginLeft: '18px',
                        }}
                        target="_blank"
                        rel="noreferrer"
                        href={'/' + view_all_link}>
                        Connect to knowledge center
                      </a>
                    </Grid>
                  </Grid>

                  <Grid container style={{ height: '47px' }}></Grid>
                </Grid>
              </div>
            ) : (
              <Grid
                container
                style={{
                  //borderBottom: "1px solid #ccc",
                  paddingBottom: '7px',
                  paddingTop: '10px',
                  position: 'absolute',
                  top: '0px',
                  zIndex: '99',
                }}>
                <Grid
                  item
                  lg={7}
                  xs={6}
                  style={{ display: 'block', justifyContent: 'center' }}>
                  <CommonHeaderText
                    text={
                      <>
                        {' '}
                        <PersonIcon style={{ fontSize: '14px' }} /> By {author}
                      </>
                    }
                    // variant="h6"
                    style={{
                      fontFamily: 'Open sans',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      color: '#FFF',
                      paddingBottom: '4px',
                      paddingLeft: '10px',
                    }}
                  />
                </Grid>

                <Grid item lg={5} xs={6} style={{ textAlign: 'right' }}>
                  <CommonHeaderText
                    text={category ?? null}
                    variant="p"
                    style={{
                      fontFamily: 'Open sans',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      color: '#FFFFFF',
                      TextAline: 'right',
                      paddingBottom: '4px',
                      paddingRight: '10px',
                      width: '100%',
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          {index == 5 ? null : (
            <>
              <Grid container style={{ height: '47px' }}>
                <CommonHeaderText
                  text={<span>{title}</span>}
                  variant="h3"
                  style={
                    isMobileView
                      ? {
                          fontFamily: 'Open Sans !Important',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          color: Variables.darkColor,
                          paddingBottom: '10px',
                          paddingTop: '20px',
                          whiteSpace: 'normal',
                          padding: '5px 10px',
                          display: '-Webkit-box',
                          lineHeight: '20px',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          height: '48px',
                        }
                      : {
                          fontFamily: 'Open Sans !Important',
                          fontWeight: 'bold',
                          fontSize: '17px',
                          color: Variables.darkColor,
                          paddingBottom: '10px',
                          paddingTop: '20px',
                          whiteSpace: 'normal',
                          padding: '5px 10px',
                          display: '-Webkit-box',
                          lineHeight: '20px',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                        }
                  }
                />
              </Grid>
            </>
          )}
          {isMobileView ? (
            index == 5 ? null : (
              <Grid
                style={{
                  paddingBottom: '0px',
                  textAlign: 'left',
                  paddingTop: '12px !important',
                  paddingLeft: '12px',
                }}>
                <CommonHeaderText
                  text={<>Last updated on {convert(date)}</>}
                  style={{
                    fontFamily: 'Open sans',
                    fontWeight: '700',
                    fontSize: '11px',
                    color: Variables.darkColor,
                    textAline: 'right',
                    paddingBottom: '4px',
                    paddingRight: '10px',
                  }}
                />
              </Grid>
            )
          ) : (
            <Grid
              style={{
                paddingBottom: '9px',
                paddingTop: '16px',
              }}>
              {index == 5 ? null : (
                <CommonHeaderText
                  text={<>Last updated on {convert(date)}</>}
                  style={{
                    fontFamily: 'Open sans',
                    fontWeight: '700',
                    fontSize: '12px',
                    color: Variables.darkColor,
                    textAline: 'left',
                    paddingBottom: '4px',
                    paddingRight: '10px',
                    paddingLeft:'10px',
                  }}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const BlogPostSection = props => {
  const {
    data,
    forwardedRef,
    open_modal,
    module_type,
    propertyDetailsGallery,
    openLoginModal,
    city,
    is_desktop,
    isMobileView
  } = props;
  const [access_token] = useAccessToken();
  const [user] = useUserInfo();
  // const [small, medium, large] = useMaterialMediaQuery();
  const [places, setplaces] = useState(0);
  const { projects, loading } = data !== undefined ? data : props.apiData;

  // TO-DO : Adding last card for length 5
  let KnowledgeCards = projects;
  if (projects.length == 5) {
    let lastCard = projects[projects.length - 1];
    KnowledgeCards.push(lastCard);
  }

  const componentRef = useRef();
  const propertyDetailsClick = (property_id, property_name) => {
    gotoPropertyDetailsPage({
      module_type: module_type,
      city_name: city?.name,
      property_name: property_name,
      property_id: property_id,
    });
  };

  const toggleWishlist = propertyid => {
    props.addWishlist({
      data: {
        property_id: propertyid,
        user_id: user?.id,
        module_type: module_type,
      },
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
    });
  };

  const firstmenu = Menu(
    KnowledgeCards,
    loading,
    open_modal,
    propertyDetailsClick,
    propertyDetailsGallery,
    toggleWishlist,
    openLoginModal,
    module_type
  );

  let result = KnowledgeCards?.map(a => {
    return a.view_all_link;
  });

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      const BlogPostProjects = _.debounce(get => {
        const queryParams = {
          city: city?.name,
          module: module_type,
        };
        if (get) {
          props.geBlogPostProjects(queryParams);
        }
      });

      if (city?.name !== null) {
        BlogPostProjects(true);
      }
    }
    return () => {
      mounted = true;
    };
  }, [city?.name]);

  const loadPrevProjects = () => {
    setplaces(prev => prev - 1);
    componentRef?.current?.handleArrowClick();
  };

  const loadNextProjects = () => {
    setplaces(1);
    componentRef?.current?.handleArrowClickRight();
  };
  const highlightedWordBlue = word => {
    return (
      <span
        style={{
          color: Variables.primaryColor,
          fontFamily: 'Open Sans, sans-serif!important',
        }}>
        {word}
      </span>
    );
  };
  const highlightedWordOrange = word => {
    return (
      <span
        style={{
          color: Variables.darkorangeColor,
          fontFamily: 'Open Sans, sans-serif!important',
        }}>
        {word}
      </span>
    );
  };
  return (
    <section
      ref={forwardedRef}
      className="main-section"
      style={{
        position: 'relative',
        bottom: isMobileView ? '0px' : '30px',
        paddingTop: isMobileView ? '0px' : null,
        marginTop: isMobileView ? '3px' : '0px',
        // marginBottom: small ? '-47px' : null,
        backgroundColor: '#FAFAFA',
      }}>
      <div className="container">
        {isMobileView ? 
        (
          <Grid container alignItems="center">
            <Grid
              item
              className={classes.txt}
              xs={10}
              justifyContent="flex-start"
              alignItems="center"
              paddingbottom="100px"
              container>
              {module_type == 'rent' ? (
                <CommonHeaderText
                  text={
                    <span>
                      {highlightedWordOrange('Top Articles ')}{' '}
                      {highlightedWordBlue(
                        `on ${capitalizeFirstLetter('Home Renting')}`
                      )}
                    </span>
                  }
                  variant="h3"
                  style={{
                    fontFamily: 'Open Sans, sans-serif !important',
                    fontWeight: 'bold',
                    lineHeight: '1',
                    fontSize: '18px',
                    color: Variables.darkColor,
                    paddingRight: '18px',
                    marginBottom: '10px',
                  }}
                />
              ) : (
                <h3 className={classes.dealWeekTitle}>
                  <>
                    {highlightedWordBlue('Top Articles ')}{' '}
                    {highlightedWordOrange(
                      `in ${capitalizeFirstLetter(city?.name)}`
                    )}
                  </>
                </h3>
              )}
            </Grid>
            <Grid item xs={2}>
              <div style={{ marginLeft: '-5px', marginTop: '-4px' }}>
                {result && result.length > 0 && result != undefined ? (
                  <ViewAll text="View All" url={'/' + result[0]} />
                ) : null}
              </div>
            </Grid>
          </Grid>
        ):(
          <Grid container style={{ paddingTop: '70px', marginBottom: '10px' }}>
          <Grid
            item
            xs={9}
            justifyContent="flex-start"
            alignItems="center"
            container
            className={classes.tagFont}>
            {module_type == 'rent' ? (
              <CommonHeaderText
                text={
                  <>
                    <span>
                      {highlightedWordOrange('Top Articles ')}on{' '}
                      {highlightedWordBlue(
                        capitalizeFirstLetter('Home Renting')
                      )}
                    </span>
                  </>
                }
                variant="h3"
                style={{
                  fontFamily: 'Open Sans, sans-serif !important',
                  fontWeight: '700',
                  lineHeight: '1',
                  fontSize: '28px',
                  color: Variables.primaryColor,
                  paddingRight: '18px',
                  marginBottom: '15px',
                  letterSpacing: '-0.00600em !important',
                }}
              />
            ) : (
              <CommonHeaderText
                text={
                  <>
                    {highlightedWordBlue('Top Articles in ')}{' '}
                    {highlightedWordOrange(capitalizeFirstLetter(city?.name))}
                  </>
                }
                variant="h3"
                style={{
                  fontFamily: 'Open Sans, sans-serif !important',
                  fontWeight: 'bold',
                  lineHeight: '1',
                  fontSize: '30px',
                  color: Variables.primaryColor,
                  paddingRight: '18px',
                  marginBottom: '20px',
                  borderRight: '3px solid #17171721',
                  letterSpacing: '-0.01562em !important',
                }}
              />
            )}
            <Grid className={classes.knowledgeText}>
              <CommonHeaderText
                text={
                  module_type !== 'rent' ? (
                    <> Knowledge Centre</>
                  ) : (
                    <span>{''}</span>
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginLeft: '-8px',
            }}>
            <div className="horizScrollPadding">
              <HorizontalScrollButtons
                loadPrevProjects={loadPrevProjects}
                loadNextProjects={loadNextProjects}
                disableNext={places === 1}
                disablePrev={places === 0}
              />
            </div>
          </Grid>
        </Grid>
        )
        }
        {/* {(medium || large) && (
          <Grid container style={{ paddingTop: '70px', marginBottom: '10px' }}>
            <Grid
              item
              xs={9}
              justifyContent="flex-start"
              alignItems="center"
              container
              className={classes.tagFont}>
              {module_type == 'rent' ? (
                <CommonHeaderText
                  text={
                    <>
                      <span>
                        {highlightedWordOrange('Top Articles ')}on{' '}
                        {highlightedWordBlue(
                          capitalizeFirstLetter('Home Renting')
                        )}
                      </span>
                    </>
                  }
                  variant="h3"
                  style={{
                    fontFamily: 'Open Sans, sans-serif !important',
                    fontWeight: '700',
                    lineHeight: '1',
                    fontSize: '28px',
                    color: Variables.primaryColor,
                    paddingRight: '18px',
                    marginBottom: '15px',
                    letterSpacing: '-0.00600em !important',
                  }}
                />
              ) : (
                <CommonHeaderText
                  text={
                    <>
                      {highlightedWordBlue('Top Articles in ')}{' '}
                      {highlightedWordOrange(capitalizeFirstLetter(city?.name))}
                    </>
                  }
                  variant="h3"
                  style={{
                    fontFamily: 'Open Sans, sans-serif !important',
                    fontWeight: 'bold',
                    lineHeight: '1',
                    fontSize: '30px',
                    color: Variables.primaryColor,
                    paddingRight: '18px',
                    marginBottom: '20px',
                    borderRight: '3px solid #17171721',
                    letterSpacing: '-0.01562em !important',
                  }}
                />
              )}
              <Grid className={classes.knowledgeText}>
                <CommonHeaderText
                  text={
                    module_type !== 'rent' ? (
                      <> Knowledge Centre</>
                    ) : (
                      <span>{''}</span>
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginLeft: '-8px',
              }}>
              <div className="horizScrollPadding">
                <HorizontalScrollButtons
                  loadPrevProjects={loadPrevProjects}
                  loadNextProjects={loadNextProjects}
                  disableNext={places === 1}
                  disablePrev={places === 0}
                />
              </div>
            </Grid>
          </Grid>
        )} */}
        <Grid
          container
          className={ClassNames([classes.BlogProject1, 'card-section'])}>
          <Grid item xs={12}>
            {!KnowledgeCards || KnowledgeCards.length === 0 ? (
              <>
                <Grid
                  spacing={3}
                  container
                  justifyContent="center"
                  alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <FallbackCard />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FallbackCard />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FallbackCard />
                  </Grid>
                </Grid>
              </>
            ) : is_desktop ? (
              <HorizontalScroll
                itemClass="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-sm-6 MuiGrid-grid-md-4 MuiGrid-grid-lg-4"
                inertiaScrolling={true}
                inertiaScrollingSlowdown={0.5}
                componentRef={componentRef}
                data={firstmenu}
                dragging={false}
                innerWrapperClass="PropertyBlogItem"
              />
            ) : (
              <MobHorizontalScrollNew
                innerWrapperClass="blog-inner-wrapper"
                itemClass="flex-shrink-0 MuiGrid-root MuiGrid-item MuiGrid-grid-xs-10 MuiGrid-grid-sm-6 MuiGrid-grid-md-4 MuiGrid-grid-lg-4"
                data={firstmenu}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

const BlogPost = handleViewport(BlogPostSection, { rootMargin: '-1.0px' });

const mapStateToProps = state => ({
  apiData: state.BlogPostReducer,
  headersApiData: state.headerReducer,
  whishlistApiData: state.addWishlistReducer,
});

const mapDispatchToProps = {
  geBlogPostProjects,
  nextBlogPostProjects,
  addWishlist,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogPost);
